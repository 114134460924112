@import "~swiper/swiper-bundle.css";

:root {
  --green-25: #edfff4;
  --green-50: #e3ffee;
  --green-300: #02cc4f;
  --green-500: #019c3c;
  --green-600: #008934;
  --green-700: #008934;
  --green-800: #025020;
  --brand-25: #edfff4;
  --brand-50: #e3ffee;
  --brand-500: #019c3c;
  --brand-600: #008934;
  --brand-700: #027b30;
  --gray-50: #f9fafb;
  --gray-100: #f2f4f7;
  --gray-200: #eeeeee;
  --gray-300: #d0d5dd;
  --gray-500: #667085;
  --gray-600: #475467;
  --gray-700: #344054;
  --gray-800: #1d2939;
  --gray-900: #101828;
  --b-gray-50: #f8f9fc;
  --b-gray-500: #4e5ba6;
  --b-gray-600: #3e4784;
  --yellow-25: #fffaeb;
  --yellow-100: #fef0c7;
  --yellow-500: #f79009;
  --yellow-600: #dc6803;
  --blue-700: #026aa2;
  --error-50: #fef3f2;
  --error-500: #f04438;
  --error-700: #b42318;
}

* {
  box-sizing: border-box;
  margin: 0;
}

.app_media_box {
  width: 60px;
  height: 60px;
  border: 1px solid #d0d5dd;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  img {
    width: 100%;
    object-fit: cover;
    height: 100%;
  }
  svg {
    width: 32;
    height: 32;
  }
}

.hidden {
  display: none;
}

// BUTTON
button.chip {
  font-size: 12px;
  height: fit-content;
  padding: 6px;
  border-radius: 4px;
  color: #333;
  border: none;
  background-color: rgba(0, 0, 0, 0.02);
  font-weight: 400;
  border: 1px solid #e0e0e0;
  white-space: nowrap;
  min-width: fit-content;
  line-height: 1.5;
}

button.chip.selected {
  color: var(--sub-color);
  background-color: #edfff4;
}

button.chip .close-icon {
  cursor: pointer;
  margin: 0 4px;
}

span.chip.quote,
p.chip.quote {
  border-radius: 16px;
  color: #008934;
  padding: 2px 8px;
  background-color: #f9fafb;
  margin-left: 8px;
  font-size: 12px;
  font-weight: 500;
}

span.chip.yl-quote,
p.chip.yl-quote {
  border-radius: 16px;
  color: #b54708;
  padding: 2px 8px;
  background-color: #fffaeb;
  margin-left: 8px;
  font-size: 10px;
  font-weight: 500;
}

.MuiCheckbox-root.fit,
.MuiButtonBase-root.fit {
  padding: 0;
}

button.fit {
  padding: 0;
}

.app-link-btn,
button.app-link-btn {
  padding: 8px;
  border-radius: 0;
  font-weight: 500;
  &:hover {
    background-color: var(--gray-100);
  }
  &,
  & p {
    line-height: 1.75;
  }
}

.tag-chip {
  background-color: var(--brand-50);
  color: var(--brand-600);
  padding: 2px 8px;
  border-radius: 6px;
}

.w-quote {
  font-size: 12px;
  padding: 3px 8px;
  background-color: #fff;
  border-radius: 16px;
  margin-left: 8px;
  font-weight: 600;
}

// TEXT
.ellipsis-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.status-label {
  padding: 2px 8px;
  border-radius: 16px;
  width: fit-content;
  line-height: 1.25;
  white-space: nowrap;
  display: flex;
  gap: 8px;
  align-items: center;
}

.status-label.green-label {
  background-color: #ecfdf3;
  color: #027a48;
}

.status-label.red-label {
  background-color: var(--error-50);
  color: var(--error-500);
}

.status-label.orange-label {
  background-color: #fff6ed;
  color: #c4320a;
}

.status-label.purple-label {
  background-color: #efd6f4;
  color: #9524ac;
}

.status-label.yellow-label {
  background-color: #f0efcd;
  color: #a4a020;
}

.status-label.gray-label {
  background-color: #f2f4f7;
  color: #667085;
}

.status-label.gray2-label {
  background-color: var(--gray-50);
  color: var(--gray-700);
}

.status-label.bgray-label {
  background-color: var(--b-gray-50);
  color: var(--b-gray-600);
}

.MuiTypography-root.input-label.required::after {
  content: "*";
  color: #f04438;
  margin-left: 2px;
}

.cutoff-text {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
}

.cutoff-text.oneline {
  --max-lines: 1;
  -webkit-line-clamp: var(--max-lines);
}

.cutoff-text.twoline {
  --max-lines: 2;
  -webkit-line-clamp: var(--max-lines);
}

.cutoff-text.fourline {
  --max-lines: 4;
  -webkit-line-clamp: var(--max-lines);
}

.cutoff-text.fiveline {
  --max-lines: 5;
  -webkit-line-clamp: var(--max-lines);
}

.showing-text {
  color: var(--gray);
  span {
    font-weight: 700;
    color: var(--sub-color);
  }
}

.badge-number {
  background-color: #f04438;
  color: #fff;
  line-height: 14px;
  padding: 2px 3px 2px;
  height: fit-content;
  border-radius: 16px;
  font-size: 10px;
  font-weight: 500;
  min-width: 18px;
  text-align: center;
}

.badge-number-sm {
  @extend .badge-number;
  padding: 1px 2px 1px;
  min-width: 14px;
  line-height: 12px;
}

.sponsored-label {
  width: fit-content;
  padding: 2px 8px;
  background-color: #fef0c7;
  border-radius: 21px;
  display: flex;
  gap: 4px;
  align-items: center;
  font-size: 10px;
  font-weight: 500;
  color: #f79009;
}

// ICON
.app-icon {
  position: relative;
  .prefer {
    top: -12px;
    background-color: #fffcf5;
    color: #dc6803;
    font-weight: 500;
    line-height: 12px;
    font-size: 8px;
    border-radius: 8px;
    padding: 0 5px;
    position: absolute;
    right: -20px;
    white-space: nowrap;
  }
}

// SCROLLBOX
.scroll-box {
  max-width: 100%;
  display: flex;
  flex-wrap: nowrap !important;
  overflow: hidden;
  overflow-x: scroll;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scroll-box::-webkit-scrollbar {
  display: "none";
}

.hor-scroll-box {
  padding-bottom: 8px;
}

// BOX
.is-seen-box {
  background-color: #f2f4f7 !important;
}

.fit-content {
  width: fit-content;
}

.cursor-point {
  cursor: pointer;
}

.card {
  background-color: #fff;
  border-radius: 12px;
  padding: 16px;
}

.sm-card {
  background-color: #fff;
  border-radius: 12px;
  padding: 8px;
}

.border {
  border: 1px solid #eaecf0;
}

.gradient-border {
  position: relative;
}

.gradient-border::before {
  content: "";
  position: absolute;
  inset: 0;
  padding: 1px;
  border-radius: inherit;
  background: linear-gradient(
    90deg,
    #fac068 0%,
    #b68040 33%,
    #f7d14e 51%,
    #a86b35 77%,
    #c3944c 100%
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
  pointer-events: none;
}

// INPUT
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

// Mobile view
@media only screen and (max-width: 600px) {
  .MuiTypography-root.input-label {
    font-size: 12px;
  }

  .card {
    border-radius: 8px;
    padding: 8px;
  }
}
