* {
  margin: 0;
}

html body {
  padding: 0;
  min-height: 100vh;
  background-color: #f9fafb;
  font-family: "Inter", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --header-green-color: #008934;
  --primary-color: #008934;
  --color: #f79009;
  --gray: #667085;
  --sub-color: #008934;
  --text-yellow-color: #e98100;
  --text-error-color: #f03e3e;
  --error-color: #f03e3e;
  --text-gray-color: #828282;
  --text-blue-color: #1a74e8;
  --bg-gray-color: #f2f2f2;
  --gray-color: #667085;
  --register-green-btn-bg: linear-gradient(
    270deg,
    #008934 18.83%,
    #2db422 100%
  );
}

.MuiTypography-root.bold {
  font-weight: 600;
}

.sm-chip {
  padding: 0px 2px;
  background-color: #fff;
  border-radius: 4px;
  border: 0.5px solid #eaecf0;
  display: flex;
  align-items: center;
}

.MuiTypography.placeholder,
span.placeholder,
.placeholder .MuiSelect-select {
  color: #ababab;
}

.MuiTypography-root.input-label {
  color: #344054;
  text-align: left;
}

a.see_all_text p {
  /* color: var(--sub-color); */
  font-size: 14px;
}

/* scroll configs */

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #bababa;
  border-radius: 8px;
}
::-webkit-scrollbar-thumb:hover {
  background: #888;
}
